$('.advantages__slider-items').slick({
	slidesToShow: 3,
	slidesToScroll: 1,
	arrows: false,
	centerMode: true,
	centerPadding: 0,
	vertical: true,
	verticalSwiping: true,
	asNavFor: '.advantages__nav-items',
	autoplay: true,
	autoplaySpeed: 5000,
	speed: 700,

	responsive: [
	    {
	      breakpoint: 980,
	      settings: {
	        vertical: false,
	        verticalSwiping: false,
	      }
	    },
	    {
	      breakpoint: 700,
	      settings: {
	      	vertical: false,
	        verticalSwiping: false,
	        slidesToShow: 1,
	      }
	    },
	]
});

$('.advantages__nav-items').slick({
	slidesToShow: 3,
	slidesToScroll: 1,
	asNavFor: '.advantages__slider-items',
	dots: false,
	arrows: false,
	centerMode: true,
	centerPadding: "80px",
	vertical: true,
	focusOnSelect: true,
	speed: 700,

	responsive: [
	    {
	      breakpoint: 980,
	      settings: {
	        vertical: false,
	        centerPadding: 0,
	      }
	    },
	    {
	      breakpoint: 700,
	      settings: {
	      	vertical: false,
	        centerPadding: 0,
	        slidesToShow: 1,
	      }
	    },
	]
});

$('.header__burger').on('click', function(){
	$(this).toggleClass('active');
	$('.header__nav').toggleClass('active');
});

$('.header__nav-item a').on('click', function(){
	$('.header__burger').removeClass('active');
	$('.header__nav').removeClass('active');
});

$(window).scroll(function(){
	if($(window).scrollTop() > 5) {
		$('.header').addClass('sticky');
	} else {
		$('.header').removeClass('sticky');
	}
});

jQuery(window).scroll(function(){
    var $sections = $('.section');
	$sections.each(function(i,el){
		var offset = 600;

		if(($(window).width() <= 1200) && ($(window).width() > 980)) {
			offset = 450;
		} else if($(window).width() <= 980) {
			offset = 420;
		}

        var top  = $(el).offset().top-offset;
        var bottom = top +$(el).height();
        var scroll = $(window).scrollTop();
        var id = $(el).attr('id');
    	if( scroll > top && scroll < bottom){
            $('.header__nav-item.anc a').parent().removeClass('active');
			$('.header__nav-item.anc a[href="#'+id+'"]').parent().addClass('active');

        }
    })
 });


/* Scroll */
	$('.header__nav-item.anc a').on('click', function(e){
		e.preventDefault();

		// $('.header__nav-item a').parent().removeClass('active');
		// $(this).parent().addClass('active');

		let selector = $(this).attr('href');
		let h = $(selector);


		$('html, body').animate({
			scrollTop: h.offset().top - 140
		}, 800);
	});
/* Scroll end */